import {
  getMessageFromError,
  getRelevantMessageFromError,
  getStack,
} from "@/lib/utils/error-handling-utils";
import debug from "debug";
import { isMyError } from "./MyError";
import { UserError } from "./UserError";

const log = debug("error:toUserError");

export function toUserError(error: unknown): UserError {
  if (error instanceof UserError) {
    log("already a UserError");
    return error;
  } else if (error instanceof Error) {
    log("is an Error");
    return new UserError({
      internal: {
        original: error,
        message: getMessageFromError(error),
        stack: getStack(error),
      },
    });
  } else if (
    // PouchDB error
    typeof error === "object" &&
    error &&
    "error" in error &&
    "reason" in error &&
    "status" in error &&
    "name" in error &&
    "stack" in error &&
    error.error &&
    error.reason &&
    error.status &&
    error.name &&
    error.stack
  ) {
    log("is a PouchDB error");
    return new UserError({
      internal: {
        original: error,
        message: `${error.name}: ${error.error} - ${error.reason}`,
        params: {
          error: `${error.error}`,
          reason: `${error.reason}`,
          status: `${error.status}`,
          name: `${error.name}`,
        },
        stack: getStack(error),
      },
    });
  } else if (isMyError(error)) {
    log("looks like a MyError");
    return new UserError(error);
  } else {
    log("unknown error format");
    return new UserError({
      internal: {
        original: error,
        message: getRelevantMessageFromError(error),
        params: {
          ...(typeof error === "object" &&
          error &&
          `additionalInfo` in error &&
          error.additionalInfo
            ? error.additionalInfo
            : {}),
        },
        stack: getStack(error),
      },
    });
  }
}
