/* eslint-disable @next/next/inline-script-id */
import Script from "next/script";
import { AppConfig } from "@/lib/nemmp/app-config/types";

type PlausibleScriptProps = {
  appConfig: AppConfig;
};

export const PlausibleScript = ({ appConfig }: PlausibleScriptProps) => {
  if (typeof window === "undefined") {
    return null;
  }

  const plausibleConfig = appConfig.plausible;

  if (!plausibleConfig?.enabled) {
    return null;
  }

  // window.localStorage.setItem('metrics-disabled', 'yes')
  if (window.localStorage.getItem("metrics-disabled") === "yes") {
    console.log(
      'Plausible script skipped because it "metrics-disabled" was set'
    );
    return null;
  }
  // window.localStorage.setItem('plausible-disabled', 'yes')
  if (window.localStorage.getItem("plausible-disabled")) {
    console.log(
      'Plausible script skipped because it "plausible-disabled" was set'
    );
    return null;
  }

  // localStorage.setItem('tracking-domain', 'test.scriptea.app')
  const trackingDomain = window.localStorage.getItem("tracking-domain");

  return (
    <>
      <Script
        defer
        data-domain={trackingDomain ?? plausibleConfig.host ?? appConfig.host}
        data-api="/api/pl"
        src="/js/pl.js"
      ></Script>
      <Script>{`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`}</Script>
    </>
  );
};
