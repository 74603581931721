export function getFileExt(path: string): string {
  const match = path.match(/(\.[a-zA-Z0-9]+)$/);
  return match ? match[1] : "";
}

export function replaceFileExt(path: string, newExt: string): string {
  const match = path.match(/(\.[a-zA-Z0-9]+)$/);
  return match ? path.replace(/(\.[a-zA-Z0-9]+)$/, newExt) : path + newExt;
}

export function getFileExtFromUrl(url: string): string {
  const match = url.split("?")[0].match(/(\.[a-zA-Z0-9]+)$/);
  return match ? match[1] : "";
}

export function withoutLineBreaks(s: string): string {
  return s.replace(/[\r\n]+/g, " ");
}
