import { appConfig } from "@/config/app-config";
import { ClientCx } from "@/framework/contexts/ClientCx";
import { UserError, isAlertableOnClient } from "@/framework/errors/UserError";
import { getErrorMessageForDeveloper } from "../errors/getErrorMessageForDeveloper";
import { toUserError } from "../errors/toUserError";
import { logger } from "../logging/client-logging";
import Rollbar from "rollbar";

const rollbar = appConfig.rollbar
  ? new Rollbar({
      ...appConfig.rollbar,
      captureUncaught: false,
      captureUnhandledRejections: false,
    })
  : null;

// Rollbar:
// https://docs.rollbar.com/reference/create-item
export const clientContext: ClientCx = {
  appConfig,
  logger,
  errorReporter: {
    error: (error: unknown): UserError => {
      const e: UserError = toUserError(error);

      console.error(
        `${getErrorMessageForDeveloper(e)}\n${JSON.stringify(
          e.details,
          null,
          2
        )}`
      );
      if (!isAlertableOnClient(e)) {
        console.log("error not alertable on client");
        return e;
      }
      if (!rollbar) {
        console.log("rollbar not enabled");
        return e;
      }

      const result = rollbar.error(getErrorMessageForDeveloper(e), e.details);
      e.details.alertId = `R-${result.uuid}`;
      return e;
    },
  },
};
