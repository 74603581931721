import { action, makeObservable, observable } from "mobx";

export class ThemeState {
  darkMode = false;

  constructor() {
    makeObservable(this, {
      darkMode: observable,
      setDarkMode: action,
    });
  }

  setDarkMode(darkMode: boolean) {
    this.darkMode = darkMode;
  }
}
