import { isPresent, withoutLineBreaks } from "@/lib/prelude";

export async function prefixErrorsWith<T>(
  prefixFn: string | ((e: Error) => void),
  subjectFn: () => Promise<T>
): Promise<T> {
  try {
    return await subjectFn();
  } catch (originalError) {
    let error: Error =
      originalError instanceof Error
        ? originalError
        : new Error(`${originalError}`);

    error.message = `${
      typeof prefixFn === "string" ? prefixFn : prefixFn(error)
    }: ${error.message}`;

    throw error;
  }
}

export function getRelevantMessageFromError(e: unknown): string | undefined {
  if (e instanceof Error) {
    let message = e.message;
    message = removePrefix(message, "Error");
    message = prependToErrorMessageIfRelevant(message, e.name);
    message = prependToErrorMessageIfRelevant(message, e.constructor.name);
    return withoutLineBreaks(message);
  }

  // if (typeof e === "string") {
  //   return withoutLineBreaks(unBlank(e));
  // }

  if (typeof e === "object" && e && "message" in e) {
    return withoutLineBreaks(`${e.message}`);
  }

  return withoutLineBreaks(`${e}`);
}

export function getMessageFromError(e: unknown): string {
  return unBlank(getRelevantMessageFromError(e) ?? "");
}

export function prependToErrorMessageIfRelevant(
  message: string,
  prefix: string
): string {
  if (prefix === "Error" || prefix === "error") {
    return message;
  }
  if (message.startsWith(`${prefix}: `) || message.startsWith(`${prefix} - `)) {
    return message;
  }
  return [prefix, message].filter(isRelevantErrorMessage).join(" - ");
}

function removePrefix(message: string, prefix: string): string {
  if (message.startsWith(`${prefix}: `)) {
    return message.slice(`${prefix}: `.length);
  }
  if (message.startsWith(`${prefix} - `)) {
    return message.slice(`${prefix} - `.length);
  }
  return message;
}

export function isRelevantErrorMessage(s: string | null | undefined) {
  return isPresent(s) && s.length > 0 && s.toLowerCase() !== "error";
}

export function unBlank(s: string): string {
  s = s.replace(/\s+/g, " ").trim();
  return s === "" ? "unknown" : s;
}

export function getStack(e: unknown): string | undefined {
  if (!e || typeof e !== "object" || !("stack" in e)) return;
  if (!("message" in e)) return;

  if (!e.stack || typeof e.stack !== "string") return;

  return e.stack;

  // const items = e.stack.split("\n");
  // if (
  //   items[0] == `${e.constructor.name}: ${e.message}` ||
  //   items[0] == `Error: ${e.message}`
  // ) {
  //   items.shift();
  // }

  // return items.map((item) => item.replace(/^(\s*at)?\s+/, ""));
}
