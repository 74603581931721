import { UserError } from "./UserError";

export function getErrorMessageForDeveloper(error: UserError) {
  const messageStack: string[] = [];

  const m =
    error.details?.internal?.message ??
    error.details?.technicalMessage ??
    error.details?.message;

  if (m) {
    messageStack.push(m);
  }

  if (error.details?.context) {
    error.details.context.forEach((c) => {
      const m = c.internal?.doing ?? c.doing;
      if (m) {
        if (messageStack.length === 0) {
          messageStack.push(`error while ${m}`);
        } else {
          messageStack.push(`occurred while ${m}`);
        }
      }
    });
  }

  return messageStack.join("; ");
}
