import { getStack } from "@/lib/utils/error-handling-utils";
import { MyError } from "./MyError";

export type ErrorOptions = {
  skipAlert?: boolean;
  // In case there's a chance the client will catch this error
  skipServerAlert?: boolean;
};

export class UserError extends Error {
  constructor(public details: MyError = {}, public options: ErrorOptions = {}) {
    super(details.message ?? "internal error");

    if (this.stack && !this.details.internal?.stack) {
      const internal = this.details.internal ?? {};
      internal.stack = getStack(this);
      this.details.internal = internal;
    }

    this.details = { ...this.details, ...options };
  }
}

export function isAlertableOnServer(error: UserError): boolean {
  return !error.details.skipAlert && !error.details.skipServerAlert;
}

export function isAlertableOnClient(error: UserError): boolean {
  return !error.details.skipAlert;
}
