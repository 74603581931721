import { LogContext, LogFn, LogLevel, Logger } from "./base";

export const log = (
  level: LogLevel,
  loggerFn: LogFn,
  context: LogContext,
  ...args: unknown[]
): void => {
  loggerFn(...args);
};

export function buildLogFn(
  level: LogLevel,
  loggerFn: LogFn,
  context: LogContext
) {
  return (...args) => {
    log(level, loggerFn, context, ...args);
  };
}

function buildLogger(context: LogContext) {
  return {
    debug: buildLogFn("debug", console.debug, context),
    info: buildLogFn("info", console.info, context),
    warn: buildLogFn("warn", console.warn, context),
    error: buildLogFn("error", console.error, context),
    fatal: buildLogFn("fatal", console.error, context),
    child: (childContext: Partial<LogContext>) => {
      return buildLogger({ ...context, ...childContext });
    },
  };
}

export const logger: Logger = buildLogger({});
