import { AppConfig } from "@/lib/nemmp/app-config/types";
import { buildConfig } from "./buildConfig.autogenerated";
import { buildAppConfig } from "./buildAppConfig";

const env = process.env["NODE_ENV"];

function validateEnv(
  env: string
): env is "development" | "test" | "production" {
  if (env !== "production" && env !== "development" && env !== "test") {
    throw new Error("invalid env: '" + env + "'");
  }
  return true;
}
validateEnv(env);

export const appConfig: AppConfig = buildAppConfig({
  ...buildConfig,
  env,
  platform: "web",
});

// console.log("appConfig:", JSON.stringify(appConfig, null, 2));

export const rootUrl = `${appConfig.https ? "https" : "http"}://${
  appConfig.host
}`;
