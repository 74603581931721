import {
  AppConfig,
  BuildConfig,
  RollbarConfig,
} from "@/lib/nemmp/app-config/types";

const tunneled = true;

export function buildAppConfig(
  buildConfig: BuildConfig,
  overrides: Partial<AppConfig> = {}
): AppConfig {
  const devprod = <T,>(devValue: T, prodValue: T): T => {
    const isProd =
      process.env["NODE_ENV"] === "production" &&
      buildConfig.env === "production";
    return isProd ? prodValue : devValue;
  };

  const mainWebOnly = <T,>(value: T): T | undefined => {
    if (buildConfig.platform === "web" && buildConfig.inst === "main") {
      return value;
    }
    return undefined;
  };

  // e.g: "staging."
  const hostPrefix =
    buildConfig.inst?.length && buildConfig.inst !== "main"
      ? `${buildConfig.inst}.`
      : "";

  const prodHost = `${hostPrefix}scriptea.app`;

  return {
    name: "scriptea",
    code: "scriptea",
    stage: "Public Beta",
    version: buildConfig.version,
    websiteUrl: `https://${prodHost}`,
    apiBaseUrl: `https://${prodHost}`,
    host: devprod("localhost:5551", prodHost),
    https: devprod(false, true),
    plausible: mainWebOnly({
      enabled: devprod(false, true),
    }),
    apiUrl: devprod(
      buildConfig.platform === "web" && !tunneled
        ? "http://localhost:5551"
        : "https://scriptea.ngrok.app",
      `https://${prodHost}`
    ),
    buildConfig,
    rollbar: devprod(undefined, getRollbarConfig()),
    // rollbar: getRollbarConfig(),
    ...overrides,
  };
}

function getRollbarConfig(): RollbarConfig {
  const accessToken =
    process.env["NEXT_PUBLIC_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN"];

  const environment: string = process.env["NODE_ENV"] ?? "development";

  const rollbarConfig: RollbarConfig =
    typeof window === "undefined" || !accessToken
      ? { enabled: false }
      : {
          accessToken,
          environment,
          enabled: true,
          captureUncaught: false,
          captureUnhandledRejections: false,
          endpoint: `${window.location.protocol}//${window.location.host}/api/rollbar/proxy`,
          payload: {
            environment,
            client: {
              javascript: {
                source_map_enabled: true,
                code_version: process.env["NEXT_PUBLIC_GIT_SHA"] ?? "unknown",
                guess_uncaught_frames: true,
              },
            },
          },
        };

  // console.log("Rollbar config:", rollbarConfig);
  return rollbarConfig;
}
