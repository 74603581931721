import z from "zod";

// Conventions:
// - message must be a sentence without capitalization of the first word or punctuation at the end, so that it can be combined with other messages.
// - messages should not contain variables, so that errors can be grouped in Rollbar, and so that they can be translated.
const ParamsZ = z.record(
  z.union([z.string(), z.number(), z.boolean(), z.null()])
);
export type Params = z.infer<typeof ParamsZ>;
const ErrorContextZ = z.object({
  doing: z.string().optional(),
  params: ParamsZ.optional(),
  internal: z
    .object({
      doing: z.string().optional(),
      params: ParamsZ.optional(),
    })
    .optional(),
});
export type ErrorContext = z.infer<typeof ErrorContextZ>;
const MyErrorBaseZ = z.object({
  message: z.string().optional(),
  technicalMessage: z.string().optional(),
  code: z.string().optional(),
  httpStatus: z.number().optional(),
  alertId: z.string().optional(),
  params: ParamsZ.optional(),
  internal: z // Must not leave the server
    .object({
      message: z.string().optional(),
      stack: z
        .union([
          z.string(),
          z.array(z.string()), // TODO: remove after deploy (DEPRECATED 2024-01-17)
        ])
        .optional(),
      params: ParamsZ.optional(),
      original: z.unknown().optional(),
    })
    .optional(),
  context: z.array(ErrorContextZ).optional(),

  skipAlert: z.boolean().optional(),
  // In case there's a chance the client will catch this error
  skipServerAlert: z.boolean().optional(),

  type: z.literal("my_error").optional(),
});

export type MyError = z.infer<typeof MyErrorBaseZ> & {
  cause?: MyError;
};

export const MyErrorZ: z.ZodType<MyError> = MyErrorBaseZ.extend({
  cause: z.lazy(() => MyErrorZ).optional(),
});

export const isMyError = (error: unknown): error is MyError => {
  const r = MyErrorZ.safeParse(error);
  return r.success ? r.data.type === "my_error" : false;
};
