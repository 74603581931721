import { AppConfig } from "@/lib/nemmp/app-config/types";
import { PlausibleScript } from "./PlausibleScript";
import { RollbarWrapper } from "./RollbarWrapper";

// Rollbar adds 25.2kb to the bundle size

type WebPageWrapperProps = {
  appConfig: AppConfig;
  children: React.ReactNode;
};

export const WebPageWrapper = ({
  children,
  appConfig,
}: WebPageWrapperProps) => {
  return (
    <>
      <PlausibleScript appConfig={appConfig} />

      <RollbarWrapper appConfig={appConfig}>{children}</RollbarWrapper>
    </>
  );
};
