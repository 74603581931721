export function isMissing<T>(
  value: T | undefined | null
): value is null | undefined {
  return typeof value === "undefined" || value === null;
}

export function isPresent<T>(value: T | undefined | null): value is T {
  return !isMissing(value);
}

export function isBlank(value: string | undefined | null) {
  return isMissing(value) || value.trim() === "";
}
