
// This file is autogenerated by deployment/scripts/generate_build_config.js

import { BuildConfig } from "@/lib/nemmp/app-config/types";

export const buildConfig: BuildConfig = {
  "platform": "web",
  "env": "production",
  "inst": "main",
  "version": "1.17.31"
};

