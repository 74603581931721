import createCache from "@emotion/cache";
import { CacheProvider, EmotionCache } from "@emotion/react"; // 84.1 - 84.1 => 0kb
// import {
//   PersistentPlayer,
//   PersistentPlayerState,
// } from "@/modules/persistent-player";
import { appConfig } from "@/config/app-config"; // 0.1 kb
import { WebPageWrapper } from "@/lib/nemmp/web-page-wrapper";
import { createCustomTheme } from "@/modules/web-base";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles"; // 0
import { AppProps } from "next/app";
import Head from "next/head"; // 0.1 kb
import { NextPage } from "next/types";
import "./styles.scss";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useMemo } from "react";
import { ThemeState } from "@/modules/app/ThemeState";
import { ThemeStateContext } from "@/modules/app/ThemeStateContext";
import { ntrpc } from "@/utils/trpc";
import { clientContext } from "@/framework/contexts/clientContext";

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

function createEmotionCache() {
  return createCache({ key: "css", prepend: true });
}

const themeState = new ThemeState();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}
const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const getLayout = Component.getLayout || ((page) => <>{page}</>);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(
    () => createCustomTheme(prefersDarkMode),
    [prefersDarkMode]
  );

  useEffect(() => {
    themeState.setDarkMode(prefersDarkMode);
    if (prefersDarkMode) {
      document.body.classList.remove("light-mode");
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
      document.body.classList.add("light-mode");
    }
  }, [prefersDarkMode]);

  useEffect(() => {
    window.onerror = function (message, file, line, col, error) {
      console.debug(
        "Error caught by window.onerror:",
        message,
        file,
        line,
        col,
        error
      );
      clientContext.errorReporter.error(error);
      return false;
    };
    window.addEventListener("error", function (e) {
      console.debug("Error caught by window.addEventListener:", e);
      clientContext.errorReporter.error(e.error);
      return false;
    });
    window.addEventListener("unhandledrejection", function (e) {
      console.debug(
        "Error caught by window.addEventListener('unhandledrejection'):",
        e
      );
      clientContext.errorReporter.error(e.reason);
      return false;
    });
  }, []);

  return (
    <WebPageWrapper appConfig={appConfig}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{appConfig.name}</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <link
            rel="icon"
            type="image/png"
            sizes="128x128"
            href="/assets/favicon.png"
          />
          <link rel="manifest" href="/manifest.json" />
        </Head>
        <ThemeProvider theme={theme}>
          <ThemeStateContext.Provider value={themeState}>
            <CssBaseline />
            {getLayout(
              <Component
                // persistentPlayerState={persistentPlayerState}
                {...pageProps}
              />
            )}

            {/* <PersistentPlayer persistentPlayerState={persistentPlayerState} /> */}
          </ThemeStateContext.Provider>
        </ThemeProvider>
      </CacheProvider>
    </WebPageWrapper>
  );
};

export default ntrpc.withTRPC(MyApp);
