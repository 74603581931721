import { appConfig } from "@/config/app-config";
import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import type { AppRouter } from "@/server/trpc/_app";

function getBaseUrl(): string {
  if (typeof window !== "undefined")
    // browser should use relative path
    return "";

  if (appConfig.apiBaseUrl) return appConfig.apiBaseUrl;

  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

const commonConfig = {
  links: [
    httpBatchLink({
      /**
       * If you want to use SSR, you need to use the server's full URL
       * @link https://trpc.io/docs/ssr
       **/
      url: `${getBaseUrl()}/api/trpc`,

      // You can pass any HTTP headers you wish here
      async headers() {
        return {
          // authorization: getAuthCookie(),
        };
      },
    }),
  ],
};

export const ntrpc = createTRPCNext<AppRouter>({
  config(opts) {
    return commonConfig;
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});

export const trpc = createTRPCProxyClient<AppRouter>(commonConfig);
