import { AppConfig } from "@/lib/nemmp/app-config/types";
import { Provider as RollbarProvider } from "@rollbar/react";

type RollbarWrapperProps = {
  appConfig: AppConfig;
  children: React.ReactNode;
};

export const RollbarWrapper = ({
  children,
  appConfig,
}: RollbarWrapperProps) => {
  const { rollbar: rollbarConfig } = appConfig;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!rollbarConfig || !rollbarConfig.enabled) return <>{children}</>;

  return <RollbarProvider config={rollbarConfig}>{children}</RollbarProvider>;
};

export default RollbarWrapper;
